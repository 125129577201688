import { useEffect, useState } from 'react';
import {
    Input,
    Icon,
    Select
} from 'react-onsenui';
import { UtilsInputLang } from "./Utils";
import Paragraph from './../components/Paragraph';

export function Form({
    status,
    help,
    values,
    setValues,
    fields
}) {
    
    useEffect(() => {
        var _values = {...values};
        fields?.forEach(field => {
            _values[field.name] = field?.defaultValue ?? '';
        })
        setValues(_values)
    }, [])

    const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

    return (
        <>
            {
                fields.map((field, index) => (
                    
                    <>
                        {
                            (field.type === 'text') && (
                                <>
                                    <Paragraph>{field.placeholder}</Paragraph>
                                    <Input
                                        key={index}
                                        style={{width: '100%', marginBottom: 15}}
                                        // value={values[field.name]}
                                        //float
                                        onChange={(event) => { 
                                            var _values = {...values};
                                            _values[field.name] = event.target.value;
                                            setValues(_values)
                                        } }
                                        //modifier='material'
                                        type={field.type}                                        
                                        placeholder={field.placeholder}
                                    />
                                    {
                                        status[field.name] == 'error' &&
                                        <small style={styles.error}>{help[field.name]}</small>
                                    }
                                </>
                            )
                        }

                        {
                            (field.type === 'password') && (
                                <div style={{ position: 'relative' }}>
                                    <Paragraph>{field.placeholder}</Paragraph>
                                    <Input
                                        key={index}
                                        style={{width: '100%', marginBottom: 15}}
                                        // value={values[field.name]}
                                        //float
                                        onChange={(event) => { 
                                            var _values = {...values};
                                            _values[field.name] = event.target.value;
                                            setValues(_values)
                                        } }
                                        //modifier='material'
                                        type={passwordVisible ? 'text' : 'password'}                            
                                        placeholder={field.placeholder}
                                    />
                                    
                                    <Icon
                                        icon={passwordVisible ?  'fa-eye' : 'fa-eye-slash'}
                                        onClick={togglePasswordVisibility}
                                        style={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        right: '10px',
                                        top: '55%',
                                        transform: 'translateY(-50%)'
                                        }}
                                    />
                                    {
                                        status[field.name] == 'error' &&
                                        <small style={styles.error}>{help[field.name]}</small>
                                    }
                                </div>
                            )
                        }

                        {
                            field.type === 'select' && (
                                <>
                                    <Paragraph>{field.placeholder}</Paragraph>
                                    <Select modifier="material"
                                        // value={values[field.name]}
                                        onChange={(event) => {
                                            var _values = {...values};
                                            _values[field.name] = event.target.value;
                                            setValues(_values)
                                        }}>
                                        <option value="1">1</option>
                                        <option value="2">2nd</option>
                                        <option value="3">3rd option</option>
                                    </Select>
                                    {
                                        status[field.name] == 'error' &&
                                        <small style={styles.error}>{help[field.name]}</small>
                                    }
                                </>
                            )
                        }

                        {
                            field.type === 'language' && (
                                <>
                                    <Paragraph>{field.placeholder}</Paragraph>
                                    <UtilsInputLang key={index} values={values} setValues={setValues} name={field.name} defaultValue={field.defaultValue} style={{ width: '100%' }} />
                                    {
                                        status[field.name] == 'error' &&
                                        <small style={styles.error}>{help[field.name]}</small>
                                    }
                                </>
                            )
                        }
                    </>
                ))
            }
            {/* <Input
                style={{width: '100%', marginBottom: 30, fontSize: 30}}
                value={values.firstName} float
                onChange={(event) => { setValues({...values, firstName: event.target.value}) } }
                modifier='material'
                type='text'
                placeholder='First name' 
            />
            {
                status.firstName == 'error' &&
                <small style={styles.error}>{help.firstName}</small>
            }

            <Input
                style={{width: '100%', marginBottom: 30, fontSize: 30}}
                value={values.lastName} float
                onChange={(event) => { setValues({...values, lastName: event.target.value}) } }
                modifier='material'
                type='text'
                placeholder='Last name' 
            />
            {
                status.lastName == 'error' &&
                <small style={styles.error}>{help.lastName}</small>
            }

            <UtilsInputLang values={values} setValues={setValues} name={'lang'} defaultValue={'fr'} style={{ width: '100%' }} />
            {
                status.lang == 'error' &&
                <small style={styles.error}>{help.lang}</small>
            }

            <Input
                style={{width: '100%', marginBottom: 30, fontSize: 30}}
                value={values.email} float
                onChange={(event) => { setValues({...values, email: event.target.value}) } }
                modifier='material'
                type='text'
                placeholder='Email' 
            />
            {
                status.email == 'error' &&
                <small style={styles.error}>{help.email}</small>
            }

            <Input
                style={{width: '100%', marginBottom: 30, fontSize: 30}}
                value={values.password1} float
                onChange={(event) => { setValues({...values, password1: event.target.value}) } }
                modifier='material'
                type='password'
                placeholder='Password' 
            />
            {
                status.password1 == 'error' &&
                <small style={styles.error}>{help.password1}</small>
            }

            <Input
                style={{width: '100%', marginBottom: 30, fontSize: 30}}
                value={values.password2} float
                onChange={(event) => { setValues({...values, password2: event.target.value}) } }
                modifier='material'
                type='password'
                placeholder='Password Check' 
            />
            {
                status.password2 == 'error' &&
                <small style={styles.error}>{help.password2}</small>
            } */}
        </>
    );
}

const styles = {
    error: {
        position: 'relative',
        top: -35,
        color: 'red',
        fontStyle: 'italic'
    }
}