import React from 'react';

const Title = ({ bold, style, centered, children, ...props }) => {

  const textStyle = {
    //color: '#FFF',
    lineHeight: 1.3,
    fontSize: 30,
    margin:0,
    textAlign: centered ? 'center' : 'left',
    fontWeight: bold ?'bold' : 'normal'
  };

  return (
      <p {...props} style={{...textStyle, ...style}}>{children}</p>
  );
};

export default Title;