

export default async function init(kernel) {
    kernel.log("Loading Session manager")

    const session = kernel.session = {}

    // manage current session
    session.current = ""
    session.store = async function (token) {
        session.current = token
        localStorage.setItem('userToken', token)
    }
    session.restore = async function () {
        try {
            const data = localStorage.getItem('userToken')
            if (!data) return
            session.current = data
        } catch (e) { }
    }

    session.login = async function (email, password) {
        const ret = await kernel.api.post("/user/signIn", { email, password })
        if (!ret) return ("Service unavailable")
        if (ret.error) return (ret.error)
        await session.store(ret.data.token)
        return (null)
    }

    session.me = async function () {
        const ret = await kernel.api.get("/user/me", { userRequest: true })
        if (!ret) return ("Service unavailable")
        if (ret.error) return (ret.error)
        return ret.data
    }

    session.clean = async function () {
        localStorage.removeItem('userToken')
    }

    await session.restore()

    kernel.log(`Getting connection status`)
    session.status = async function () {
        const data = {
            token: kernel.session.current,
            // signature: kernel.pki.sign(kernel.session.current)
        }
        // const message = kernel.pki.encrypt(kernel.api.serverPublic.dh, JSON.stringify(data))

        // session.heartbeat = await kernel.api.post("/user/heartbeat", { message })
        // if (!session.heartbeat) {
        //     kernel.fatal(`Server error`)
        //     return
        // }

        // if(session.heartbeat.allowed === false) 
        //     return

        // session.store(session.heartbeat.token)

        // setTimeout(session.status, 10 * 1000)
    }
    await session.status()

}

