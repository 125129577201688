
import { useState, useEffect } from 'react'
import { ActionSheet, AlertDialogButton, AlertDialog } from 'react-onsenui';
import { notification } from 'antd'


const CustomAlertDialog = ({
    title,
    subTitle,
    buttons,
    setAlertDialog
}) => {

    return (
        <AlertDialog modifier='rowfooter' isOpen={true} animation='default'
            animationOptions={{
                duration: .5,
                delay: 0.1,
                timing: 'ease-in'
            }}

            onCancel={() => setAlertDialog(<></>)} isCancelable={false} >
            <div className="alert-dialog-title">{title}</div>
            <div className="alert-dialog-content">
                {subTitle}
            </div>
            <div className="alert-dialog-footer">
                {
                    buttons?.map((button) => (
                        <AlertDialogButton style={{color: button?.color}} onClick={() => button.func()}>
                            {button?.label}
                        </AlertDialogButton>
                    ))
                }
            </div>
        </AlertDialog>
    )
}

export default CustomAlertDialog;