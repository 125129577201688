import React from 'react';

const SmallText = ({ children }) => {

  const textStyle = {
    color: '#FFF',
    fontSize: 15,
    lineHeight: 1.5,
    textAlign:'left'
  };

  return (
      <span style={textStyle}>{children}</span>
  );
};

export default SmallText;