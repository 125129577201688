import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

var lang=navigator.language?.split('-')?.[0];

console.log('lang : ', lang);

// let loadResources= apiDelegate.getTranslations(lang);

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    defaultNS: "locale",
    ns: "locale",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
        loadPath: '/locale/{{lng}}.json' // `http://localhost:3000/api/app/{{lng}}`,
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
