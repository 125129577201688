
import { Segmented, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { AlertDialog, AlertDialogButton, Button, Carousel, CarouselItem, Col, Icon, Input, Row, Switch } from 'react-onsenui';
import { Content } from '../Templates';
import Paragraph from './../components/Paragraph';
import Title from './../components/Title';

const LogsColors = {
    blocking: "2px solid #a14838",
    detecting: "2px solid #e58900",
    passed: "2px solid #22d933",
    bypassing: "2px solid #4974a5",
}


export function ListManagement({ kernel }) {

    const [index, setIndex] = useState(1);
    const [input, setInput] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState('allow');
    const [domains, setDomains] = useState([]);
    const [alertDialog, setAlertDialog] = useState(<></>);

    useEffect(() => {
        async function fetch() {
            setLoading(true)
            const ret = await kernel.api.get(`/dohzel/ablist/list?list=${list}`);
            setLoading(false)
            if (!ret || ret.error) {
                notification.error({
                    message: 'Error adding rule',
                    description: ret.error
                });
                return;
            }
            setDomains(ret.data.map(item => ({...item, ref: React.createRef()})))
        }
        fetch();
    }, [loaded, list]);

    const handleList = (type) => {
        setList(type);
        setInput("");
    }

    const add = async () => {
        const data = {
            list: list,
        };
        
        var masked = input.split("/");
        var detection = "Domain";
        if (/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/.test(masked[0])) {
            detection = "IPv4";
            data.type = "cidr";
            data.cidr = input;
        }
        else if (masked[0].indexOf(":") > 0) {
            detection = "IPv6";
            data.type = "cidr";
            data.cidr = input;
        }
        else {
            data.type = "domain";
            const t = input.split(".");
            if (t[0] === "*") {
                data.domainWildcard = true;
                t.shift();
                data.domain = t.join(".");
            }

            else
                data.domain = input;
        }

        const ret = await kernel.api.post('/dohzel/ablist/create', data)
        if (!ret || ret.error) {
            notification.error({
                message: 'Error adding rule',
                description: ret.error
            });
            return;
        }

        setInput("");
        setLoaded(!loaded);
    }


    return (
        <Content back="/settings" backTransition="outRight" topAlign={true}>
            <div className='container' id="page" style={{height:'90dvh', width:'90dvw'}}>

                <div className="audit" >
                    <Title centered>List management</Title>
                    <div style={{ paddingTop: 30, fontSize: 10, marginBottom: 50 }}>
                        <Segmented
                            options={['Allow list', 'Block list']}
                            block
                            size='large'
                            onChange={(value) => {
                                 value==='Allow list' ? handleList('allow'): handleList('block')
                            }}
                        />
                        {/* <Segment modifier="material" style={{width: '100%'}}>
                            <button onClick={() => handleList('allow')}>Allow list</button>
                            <button onClick={() => handleList('block')}>Block list</button>
                        </Segment> */}
                        <div className='form-container' style={{margin: '25px 0'}}>
                                <Paragraph>Type domain or IP</Paragraph>
                                <Row style={{  justifyContent: 'space-between',
    alignItems: 'center'}}> 
                                    <Col width={'75%'}>
                                        <Input
                                                //float
                                                value={input}
                                                style={{fontSize: 44, display:'flex'}}
                                                onChange={(event) => { setInput(event.target.value) } }
                                                //modifier='material'
                                                placeholder='domain or IP'
                                            />
                                    </Col>
                                    <Col width={'25%'}>
                                        {
                                            loading ? 
                                            <ons-icon
                                                style={{ fontSize: 50,float: 'right'}}
                                                icon="md-spinner"
                                                spin>
                                            </ons-icon>
                                            :
                                            <Button onClick={() => add()} style={{float: 'right', display:'flex'}} modifier="cta">
                                                Add
                                            </Button>
                                        }
                                    </Col>
                            </Row>
                                        
                            </div>

                        <div style={{ marginTop: 20 }}>     
                            {
                                domains.map((domain) => (
                                    <Carousel
                                        key={domain.id}
                                        ref={domain.ref}
                                        style={{width: '100%', borderLeft: domain.list == 'allow' ?  LogsColors.passed : LogsColors.blocking, marginBottom: 20}}
                                        animationOptions={{
                                            duration: 1.0,
                                            delay: 0.3,
                                            timing: 'ease-in'
                                        }}
                                        activeIndex={1}
                                        onPostChange={(event) => setIndex(event.activeIndex)}
                                        swipeable overscrollable autoScroll fullscreen autoScrollRatio={0.2}>

                                        <CarouselItem>
                                            <Button 
                                                color={'red'}
                                                style={{background: 'red'}} 
                                                modifier="large--cta"
                                                onClick={() => {
                                                    setAlertDialog (
                                                        <AlertDialog modifier='rowfooter' isOpen={true} animation='default'
                                                            animationOptions={{
                                                            duration: .5,
                                                            delay: 0.1,
                                                            timing: 'ease-in'
                                                            }}
                                                
                                                            onCancel={() => setAlertDialog(<></>)} isCancelable={false} >
                                                            <div className="alert-dialog-title">Deletion!</div>
                                                            <div className="alert-dialog-content">
                                                            Are you sure ?
                                                            </div>
                                                            <div className="alert-dialog-footer">
                                                            <AlertDialogButton onClick={() => {
                                                                domain.ref.current.setAttribute('active-index', 1)
                                                                setAlertDialog(<></>)
                                                            }}>
                                                                Cancel
                                                            </AlertDialogButton>
                                                            <AlertDialogButton 
                                                                style={{color: 'red'}}
                                                                onClick={async () => {
                                                                    const data = { id: domain.id };

                                                                    setLoading(true);
                                                                    const ret = await kernel.api.post(`/dohzel/ablist/remove`, data);
                                                                    setLoading(false);
                                                                    if (!ret || ret.error) {
                                                                        notification.error({
                                                                            message: 'Error deleting entry',
                                                                            description: ret.error
                                                                        });
                                                                        return;
                                                                    }

                                                                    setLoaded(!loaded);
                                                                    setAlertDialog(<></>)
                                                                }}
                                                            >
                                                                Yes
                                                            </AlertDialogButton>
                                                            </div>
                                                        </AlertDialog>
                                                    )
                                                }}
                                            >
                                                <Icon size={{default: 30, material: 35}} style={{color: "white"}} icon="fa-trash" />  
                                            </Button>
                                        </CarouselItem>
                                        <CarouselItem style={styles.container}>
                                            <div style={styles.verticalCenter}>
                                                <Row> 
                                                    <Col width={'80%'}>
                                                        {
                                                            domain.type === "cidr" ? 
                                                                <h4 style={styles.listItemStyle /* {fontSize: 25, margin: 'auto', marginLeft: 10} */}> {domain.cidr}</h4>
                                                            :
                                                                <h4 style={styles.listItemStyle /* {fontSize: 25, margin: 'auto', marginLeft: 10} */}> {domain.domainWildcard === true ? '*.' : ''}{domain.domain}</h4>
                                                        }
                                                    </Col>
                                                    <Col style={{margin: 'auto'}}>
                                                        <Switch 
                                                            style={{float: 'right'}} 
                                                            checked={domain.activate}
                                                            modifier="material"
                                                            onChange={async () => {
                                                                const data = {
                                                                    id: domain.id,
                                                                    activate: !domain.activate
                                                                };
                                                                setLoading(true);
                                                                const ret = await kernel.api.post(`/dohzel/ablist/update`, data);
                                                                setLoading(false);
                                                                if (!ret || ret.error) {
                                                                    notification.error({
                                                                        message: 'Error changing mode',
                                                                        description: ret.error
                                                                    });
                                                                    return;
                                                                }

                                                                setLoaded(!loaded);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CarouselItem>
                                    </Carousel>
                                ))
                            }
                        </div>
                        {alertDialog}
                    </div>
                </div>
            </div>
        </Content>
    );
}

const styles = {
    listItemStyle: {
        fontSize: '25px',
        margin: 'auto auto auto 10px',
        width: '80%',
        display: 'inline-block',
        wordWrap: 'break-word',
    },
    button: {
        width: '100%', 
        height: 60, 
        fontSize: 20, 
        marginBottom: 20, 
        marginTop: 20
    },
    subTitle: {
        fontSize: 15,
        color: 'green'
    },
    container: {
        position: 'relative',
        margin: 'auto'
    },
    verticalCenter: {
        margin: 0,
        top: '50%',
    }
}