
const CSSlogoLight = {
    // fontFamily: "Monteserrat",
    fontSize: 16,
    fontWeight: 800,
    // position: "fixed",
    color: "#B7CED9"
}

const CSSlogoDark = {
    // fontFamily: "Monteserrat",
    fontSize: 16,
    fontWeight: 800,
    // position: "fixed",
    color: "#363D41"
}

export function Logo({ dark, tag = "beta", fontSize=16 }) {
    return (
        <div style={{ ...dark !== true ? CSSlogoLight : CSSlogoDark, fontSize }}>
            DOHZEL <span style={{ fontSize: "0.4em" }}>AV</span>
            <span className={`tag-${tag}`} style={{ position: "relative", fontSize: "0.5em", marginLeft: "5px", top: -10 }}>
                {tag.toUpperCase()}
            </span>
        </div>
    )
}

export { Content } from './Content'