import { useNavigate } from "react-router-dom";
import { Content } from '../../Templates';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Logo from '../../components/Logo';
import { useTranslation } from "react-i18next";

export function InvalidCode({ kernel }) {

    const navigate = useNavigate()

    const { t, i18n, ready } = useTranslation();

    if (ready) {
        return (
            <Content back="PREVIOUS" backTransition="outRight" topAlign={true}>
                <div id="page">
                    <div className="container" style={styles.container}>
                        <Logo />
                        <Title centered>{t('InvalidCode.title')}</Title>

                        <div style={styles.content}>
                            <Paragraph centered>
                                {t('InvalidCode.content')}
                            </Paragraph>
                        </div>
                        <Button onClick={() => navigate('/install/EnrollCode')} modifier="cta">
                            {t('InvalidCode.backButton')}
                        </Button>
                    </div>
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    container: {
        display: 'flex',
        flexDirection:'column',
        alignItems:'center',
        textAlign: 'center',
    },
    title: {
        fontWeight: 600,
        fontSize: 40,
    },
    input: {
        color: '#FFFFFF',
        fontSize: 40,
    },
    centerContent: {
    },
    content: {
        marginTop: 30,
        marginBottom:50
    },
    buttonContent: {
        display: 'block'
    },
    questionContainer: {
    },
    question: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: 18,
        padding: 5
    },
    button: {
    }
}