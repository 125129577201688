import { continents, countries, languages } from "countries-list";
import { 
    Select 
} from 'react-onsenui';
 
languages.languagesAllSelect = [];
for (var key in languages) {
    const lang = languages[key]
    lang.code = key
    languages.languagesAllSelect.push({
        value: lang.code,
        label: lang.native
    })
}

/*
CountryList.countriesSelect = []
for (var key in CountryList.countries) {
    const item = CountryList.countries[key]
    item.code = key
    CountryList.countriesSelect.push({
        value: item.code,
        label: item.name
    })
}*/

export function UtilsInputLang(props) {
    return (
        <Select className="language-container" modifier="material"
            style={{width: '100%', marginBottom: 30, fontSize: 30}}
            value={props.name} float
            defaultValue={props.defaultValue}
            onChange={(event) => {
                var user = {...props.user};
                user[props.name] = event.target.value
                props.setUser(user)
            }}>
            <option key={'language'} disabled>Language</option>
            {
                languages.languagesAllSelect.map((language) => (
                    <option key={language.value} value={language.value}>{language.label}</option>
                ))
            }
        </Select>
    )
}
/*
export function UtilsInputCountry(props) {
    return (
        <Select
            {...props}
            // defaultValue={navigator.language.split("-")[0]}
            options={CountryList.countriesSelect}
            showSearch
        />
    )
}

export function UtilsHidePart(apiKey, startVisibleChars, endVisibleChars, maskChar = '*', noRepeat = false) {
    const apiKeyLength = apiKey.length;
    if (apiKeyLength <= startVisibleChars + endVisibleChars)
        return apiKey;
    if (noRepeat === false) {
        const visiblePart = apiKey.slice(0, startVisibleChars) + maskChar.repeat(apiKeyLength - startVisibleChars - endVisibleChars) + apiKey.slice(apiKeyLength - endVisibleChars);
        return visiblePart;
    }
    const visiblePart = apiKey.slice(0, startVisibleChars) + maskChar + apiKey.slice(apiKeyLength - endVisibleChars);
    return visiblePart;
}

export function UtilsConvertTime(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24)) % 30;
    const months = Math.floor(milliseconds / (1000 * 60 * 60 * 24 * 30));

    const result = [];
    if (months > 0) {
        result.push(months + (months === 1 ? ' month' : ' months'));
    }
    if (days > 0) {
        result.push(days + (days === 1 ? ' day' : ' days'));
    }
    if (hours > 0) {
        result.push(hours + (hours === 1 ? ' hour' : ' hours'));
    }
    if (minutes > 0) {
        result.push(minutes + (minutes === 1 ? ' minute' : ' minutes'));
    }
    if (seconds > 0) {
        result.push(seconds + (seconds === 1 ? ' second' : ' seconds'));
    }

    return result.join(', ');
}

export function UtilsCleanEmptyStrings(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(UtilsCleanEmptyStrings);
    }

    const cleanedObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value === null) continue
            if (typeof value === 'string' && value.length === 0) continue

            cleanedObj[key] = UtilsCleanEmptyStrings(value);
        }
    }

    return cleanedObj;
}
*/