import React from 'react';

const BigText = ({ bold, centered, children, ...props }) => {

  const textStyle = {
    //color: '#FFF',
    lineHeight: 1.3,
    fontSize: 35,
    margin:0,
    textAlign: centered ? 'center' : 'left',
    fontWeight: bold ?'bold' : 'normal'
  };

  return (
      <p {...props} style={textStyle}>{children}</p>
  );
};

export default BigText;