
import { useState, useEffect } from 'react'
import { Icon, Switch } from 'react-onsenui';
import { notification } from 'antd'
import { Content } from '../../Templates'
import BigText from '../../components/BigText';
import Paragraph from '../../components/Paragraph';
import { useTranslation, Trans } from "react-i18next";


const Tag = ({
    kernel
}) => {

    const [tags, setTags] = useState([]);
    const [activeTags, setActiveTags] = useState(kernel.api.security ?? []);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            const response = await kernel.api.get('/dohzel/tags')
            if (response.error) {
                return;
            }
            setTags(response.data.map(tag => ({ ...tag, collapsed: false, active: false })));
        })()
    }, []);



    const collapseTag = (currentTag) => {
        const alltags = [...tags];
        const _tag = alltags?.find(tag => tag.name === currentTag.name);
        _tag.collapsed = !currentTag.collapsed;
        setTags(alltags);
    };


    const activeTag = async (currentTag) => {
        const foundTag = activeTags?.find((tag) => tag == currentTag.name);
        let security = [];
        if (!foundTag) {
            security = [...activeTags, currentTag.name];
        }
        else {
            security = activeTags.filter((tag) => tag !== currentTag.name)
        }
        setActiveTags(security ?? []);
        const data = {
            security
        };
        setLoading(true);
        const ret = await kernel.api.post(`/dohzel/device/change`, data);
        setLoading(false);
        if (!ret || ret.error) {
            notification.error({
                message: t("Tag.erreurChangingPolicy"),
                description: ret.error
            });
            return;
        }
        setLoaded(!loaded);
    };

    const { t, i18n, ready } = useTranslation();
    
    if (ready) {
        return (
            <Content back="/settings" backTransition="outRight" topAlign={true}>
                <div className='container' id="page" style={{height:'90dvh', width:'90dvw'}}>

                    <div>

                        <div style={{marginBottom: 30, marginTop: 20}}>
                            <BigText centered bold>{t("Tag.title")}</BigText>
                        </div>

                        <Paragraph style={{textAlign: 'center'}}>{t("Tag.content")}</Paragraph>
                        
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                tags && tags.map(tag => (
                                    <div>
                                        <span onClick={() => collapseTag(tag)} style={styles.collapseTitle}>
                                            <Icon size={{ default: 20, material: 25 }} style={{ color: "#FFFFFF", marginRight: 5 }} icon={tag.collapsed ? 'fa-chevron-down' : 'fa-chevron-right'} />
                                            {tag.brief ? tag.brief : tag.name}
                                        </span>
                                        <Switch modifier="material" style={{ float: 'right' }} checked={activeTags?.includes(tag.name)} onChange={() => activeTag(tag)} />
                                        {
                                            tag.collapsed &&
                                            <div style={styles.collapseContent}>
                                                <p>
                                                    {tag.description}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Content>
        )
    }
    return <span>{t('Loading')}</span>;
}



const styles = {
    collapseTitle: {
        float: 'left',
        textAlign: 'left',
        marginBottom: 20,
        fontWeight: 900,
        width: '85%'
    },
    collapseContent: {
        display: 'inline-table',
        textAlign: 'left'
    },
    listItemStyle: {
        fontSize: '25px',
        margin: 'auto auto auto 10px',
        width: '80%',
        display: 'inline-block',
        wordWrap: 'break-word',
    },
    button: {
        width: '100%', 
        height: 60, 
        fontSize: 20, 
        marginBottom: 20, 
        marginTop: 20
    },
    subTitle: {
        fontSize: 15,
        color: 'green'
    },
    container: {
        position: 'relative',
        margin: 'auto'
    },
    verticalCenter: {
        margin: 0,
        top: '50%',
    }
}

export default Tag;