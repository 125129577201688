import React from 'react';

const Paragraph = ({ centered, children }) => {

  const textStyle = {
    color: '#FFF',
    fontSize: 19,
    lineHeight: 1.7,
    textAlign:centered ? 'center' : 'left'
  };

  return (
      <p style={textStyle}>{children}</p>
  );
};

export default Paragraph;