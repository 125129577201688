import { useState } from 'react';
import { ActionSheet, ActionSheetButton, Icon } from 'react-onsenui';
import { useNavigate } from "react-router-dom";
import { Content } from '../../Templates';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Logo from '../../components/Logo';
import { useTranslation } from "react-i18next";

export function Logs({ kernel }) {
    
    const { t, i18n, ready } = useTranslation();

    const logs = [
        {
            title: t('Log.allLog'),
            value: 'all'
        },
        {
            title: t('Log.onlySecurityLog'),
            value: 'onlySecurity'
        },
        {
            title: t('Log.noneLog'),
            value: 'none'
        }
    ]

    const installProcess = JSON.parse(localStorage.getItem('installProcess') || '{}');
    const [more, setMore] = useState(false)
    const [logToProfile, setLogToProfile] = useState(installProcess?.logToProfile ? logs?.find(log => log?.value == installProcess?.logToProfile) : {
        title: t('Log.allLog'),
        value: 'all'
    })
    const [actionSheet, setActionSheet] = useState(<></>);

    const navigate = useNavigate();

    const codeType = installProcess?.codeType;

    const freeCode = codeType == 'free';
    const tags = installProcess.tags;

     if (ready) {
        return (
            <Content back="PREVIOUS" backTransition="outRight" topAlign={true}>
                <div id="page" className='container'>
                    <div style={styles.container}  className="vertical-center">
                        <div style={styles.title}>
                            <Title centered>{t('Log.title')}</Title>
                        </div>

                        <div style={styles.content}>
                            <p style={styles.centerContent}>
                                <Paragraph centered>{t('Log.content')}</Paragraph>
                                {
                                    more && 
                                    <Paragraph centered> {t('Log.moreText')} </Paragraph>    
                                }           
                            </p>
                            <Button onClick={() => setMore(!more)} modifier="cta">
                                {more ? t('Log.lessButton') : t('Log.moreButton')}
                            </Button>
                        </div>

                        <div style={styles.forwarding}>
                            {/* 
                            <div style={{textAlign: 'left', margin: 20}}>
                                <Paragraph>Forwarding logs</Paragraph>
                            </div> */}
                            <Title 
                            centered 
                            style={{border: '0.1px solid #FFFFFF', fontSize: 25, borderRadius: 10, padding: 10}}
                            onClick={() => {
                                        setActionSheet(
                                        <ActionSheet 
                                            isOpen={true} 
                                            animation='default'
                                            animationOptions={{
                                                duration: 0.6,
                                                delay: 0.1,
                                                timing: 'ease-in'
                                            }}
                                            onCancel={() => {
                                                setActionSheet(<></>)
                                            }}
                                            isCancelable={true}
                                            title={t('Log.forwardingLogText')}
                                            maskColor="ffffff96"
                                            >
                                            {
                                                logs.map((item, index) => (
                                                    <ActionSheetButton onClick={async () => {
                                                        setLogToProfile(item)
                                                        setActionSheet(<></>);
                                                    }} key={index}>{item.title}
                                                    </ActionSheetButton>
                                                ))
                                            }
                                            <ActionSheetButton style={{color: '#c0392b'}} onClick={() => {setActionSheet(<></>)}}>{t('Log.cancelButton')}</ActionSheetButton>
                                        </ActionSheet>)
                                    }}> {logToProfile?.title} <Icon size={{ default: 30, material: 35 }} icon={'fa-caret-down'} /></Title>
                                
                            <div style={styles.button}>
                                <Button onClick={() => {
                                    localStorage.setItem('installProcess', JSON.stringify({...installProcess, logToProfile: logToProfile?.value}))
                                    navigate('/install/device', { state: { freeCode, code: installProcess.code, tags, logs, logToProfile: logToProfile?.value} });
                                } } modifier="cta">
                                    {t('Log.continueButton')}
                                </Button>
                            </div>
                        </div>

                        {actionSheet}

                        
                    </div>
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    container: {
        textAlign:'center'
    },
    title: {
        marginTop: 60,
        fontWeight: 700,
        fontSize: 45
    },
    content: {
        fontSize: 25
    },
    centerContent: {
        margin: 20,
        // textAlign: 'left'
    },
    description: {
        fontSize: 23,
    },
    forwarding: {
        fontSize: 20,
        margin: 10,
        marginTop: 50
    },
    button: {
        margin: 50
    }
}