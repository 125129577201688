import { useState } from 'react';
import { kernel } from '../../App'
import {Spin} from 'antd'
import logoBig from '../../Images/logoBig.png'
import { Content } from '../../Templates'
import { Page, Icon, ListTitle, ProgressCircular, Row, Col } from 'react-onsenui';
import { Form } from '../../Templates/Form';
import { useNavigate } from "react-router-dom";
import Button from '../../components/Button'
import { useTranslation, Trans } from "react-i18next";

import {
    notification,
} from 'antd';
import Logo from '../../components/Logo';
import Title from './../../components/Title';


const CSScenter = {
    position: "relative",
    textAlign: "center",
}

export function Login({
}) {

    const [status, setStatus] = useState({})
    const [help, setHelp] = useState({})
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    
    const { t, i18n, ready } = useTranslation();

    const onFinish = async () => {
        const values = {...user};
        setLoading(true)

        const ret = await kernel.session.login(values.email, values.password)
        if (ret) {
            notification.error({
                message: ret
            })
            setLoading(false);
            return
        }
        notification.success({
            message: t("Success")
        })
        navigate('/settings')
    }


    const fields = [
        {
            name: 'email',
            placeholder: t("Login.email"),
            type: 'text',
            defaultValue: ''
        },
        {
            name: 'password',
            placeholder: t("Login.password"),
            type: 'password',
            defaultValue: ''
        }
    ]
    
    if (ready) {
        return (
            <Content back="/settings" backTransition="outRight" topAlign={true}>
                
                <div id='page' className='container' style={{maxWidth: 600, margin: 'auto', overflowY: 'scroll', maxHeight: '90vh'}} >
                    {/* <div className="center" style={CSScenter}>
                        <div id="logo" style={{marginTop: 50}}>
                            <img src={logoBig} width={150} />
                        </div>
                    </div> */}
                    <Logo />
                            
                    <Title centered bold>{t("Login.title")}</Title> 

                    <div className='form-container'>
                        <Form help={help} status={status} values={user} setValues={setUser} fields={fields}/>
                        <div> 
                            <div className='forgot-container'>
                                <span className='forgot' onClick={() => navigate('/session/forgot-password')} style={{color: "#FFFFFF"}} modifier="quiet">
                                    {t("Login.forgotenPassword")}
                                </span>
                            </div>
                            <div className='btn-container'>
                                {
                                    !loading ? 
                                        <Button onClick={onFinish} modifier="cta">
                                            {t("Login.button")}
                                        </Button>
                                    :
                                        <ProgressCircular indeterminate />
                                }
                            </div>
                        </div>

                        <div className="center">
                            <span className='no-account-btn' onClick={() => navigate('/session/signup')} style={{color: "#FFFFFF", textDecoration: 'underline'}} modifier="quiet">
                                {t("Login.noAccount")}
                            </span>
                        </div>
                    </div>
                </div>

            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}