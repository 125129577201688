
import { useState, useEffect } from 'react'
import { ActionSheet, ActionSheetButton } from 'react-onsenui';
import CustomAlertDialog from './AlertDialog';
import { useTranslation, Trans } from "react-i18next";


const CustomActionSheet = ({
    title,
    buttons,
    func,
    cancel,
    selected
}) => {

    const { t, i18n, ready } = useTranslation();
    
    if (ready) {
        return (
            <ActionSheet 
                isOpen={true} 
                animation='default'
                animationOptions={{
                    duration: 0.6,
                    delay: 0.1,
                    timing: 'ease-in'
                }}
                onCancel={() => {
                    cancel()
                }}
                isCancelable={true}
                title={title}
                maskColor="ffffff96"
                >
                {
                    buttons?.map((item, index) => (
                        <ActionSheetButton key={item.id ?? index} onClick={() => func(item, index)} style={{background: selected == item.value ? '#f6f6f6' : '#ffffffe6'}}>
                            {item.name}
                        </ActionSheetButton>
                    ))
                }
                <ActionSheetButton style={{color: '#c0392b'}} onClick={() => {cancel()}}>{t('CancelButton')}</ActionSheetButton>
            </ActionSheet>
        )
    }
    return <span>{t('Loading')}</span>;
}

export default CustomActionSheet;