import { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Content } from '../../Templates'

import {
    GoogleLoginButton,
    MicrosoftLoginButton,
    AppleLoginButton,
    createButton
} from "react-social-login-buttons";

const config = {
    text: "Log in with email",
    icon: "email",
    iconFormat: name => `fa fa-${name}`,
    style: { background: "#0073AB" },
    activeStyle: { background: "#198CC5" }
};
const ContinueEmail = createButton(config)

export function Select({ }) {
    const navigate = useNavigate()
    return (
        <Content>
            <div style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: 10,
                padding: 20,
                margin: "0 auto",
                maxWidth: 500
            }}>
                <ContinueEmail onClick={()=>navigate("/session/login")}/>
                <hr size={1} />
                <AppleLoginButton />
                <GoogleLoginButton />
                <MicrosoftLoginButton />
            </div>

        </Content>
    );
}