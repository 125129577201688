import { useEffect, useState } from 'react';

import { Space, Tag } from 'antd'
import anime from 'animejs'

import { Logo } from '.'

import { CloseOutlined } from '@ant-design/icons';

import { kernel } from '../App'

export function UpgradeToPro({ onClose }) {
    const topBanner = 0;
    const padding = 0;
    const radius = 15;
    const inPadding = 10;

    const [loaded] = useState(true);

    useEffect(() => {
        anime({
            targets: ['#upgrade-to-pro'],
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration: 500,
            translateY: [window.innerHeight, 0],
        })
    }, loaded)

    function close() {
        anime({
            targets: ['#upgrade-to-pro'],
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration: 500,
            translateY: [0, window.innerHeight],
            complete: onClose
        })
    }

    return (
        <div id="upgrade-to-pro" style={{
            zIndex: 20,
            position: "fixed",
            left: padding,
            top: topBanner,
            height: window.innerHeight - topBanner,
            width: window.innerWidth - padding * 2,
            borderRadius: radius,
            textAlign: "center",
            padding: inPadding,
            color: "white",
            overflow: "auto",
            backgroundColor: "rgba(0, 45, 68, 1)",
        }}>
            <div style={{
                margin: "0 auto",
                maxWidth: 700,
            }}>

                <div style={{
                    // position: "absolute",
                    // right: 0,
                    padding: 20,
                    paddingTop: 0,
                    paddingRight: 20,
                    cursor: "pointer",
                    textAlign: "right",
                    // backgroundColor: "red"
                }} onClick={close}>
                    <CloseOutlined />
                </div>

                <div style={{ padding: 20, paddingTop: 0 }}>
                    <Space>Upgrade to <Logo dark={false} tag="pro" fontSize={22} /></Space>
                </div>

                <div style={{ padding: 10, fontWeight: 400, fontSize: 14 }}>
                    Get unlimited access to all the features of our PRO offer!
                </div>

                <div style={{ padding: 10, fontWeight: 500, fontSize: 14, lineHeight: 2, textAlign: "left" }}>
                    <div>✅ Protect yourself against phishing and viruses</div>
                    <div>✅ Enjoy prioritized continuous monitoring</div>
                    <div>✅ Explore unlimited modes</div>
                    <div>✅ Access advanced protection lists</div>
                    <div>✅ Utilize advanced features for your alerts</div>
                    <div>✅ Ensure your peace of mind</div>
                </div>

                {!kernel.api.stripe && kernel.device.iosApp === true ?
                    <div>
                        <div style={{ paddingTop: 20, fontWeight: 400, fontSize: 12 }}>
                            Launch Offer, Save 30%
                        </div>

                        <div style={{ color: "#161818" }}>
                            <div style={{ padding: 20, background: "linear-gradient(0deg, #E2E2E2, #F0F0F0)", borderRadius: 4 }}>
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "left" }}>
                                                <div style={{ fontWeight: 600, fontSize: 16 }}>12 mois</div>

                                                <div style={{ fontWeight: 400, fontSize: 10 }}>
                                                    <Tag color="#3A5C84" style={{ fontSize: 10 }}>Essai de 14 jours</Tag>
                                                </div>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <div style={{ fontWeight: 500, fontSize: 16 }}>CHF 43 .- / an</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ marginTop: 10, padding: 20, background: "linear-gradient(0deg, #E2E2E2, #F0F0F0)", borderRadius: 4 }}>
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "left" }}>
                                                <div style={{ fontWeight: 600, fontSize: 16 }}>1 mois</div>

                                                <div style={{ fontWeight: 400, fontSize: 10 }}>
                                                    <Tag color="#3A5C84" style={{ fontSize: 10 }}>Essai de 7 jours</Tag>

                                                </div>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <div style={{ fontWeight: 500, fontSize: 16 }}>CHF 3.9 .- / mois</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    : null}

                {kernel.api.stripe ?
                    <div style={{ paddingTop: 20 }}>
                        <stripe-pricing-table
                            pricing-table-id={kernel.api.stripe.id}
                            publishable-key={kernel.api.stripe.key}
                            client-reference-id={kernel.api.dohToken}
                        // customer-email="{{CUSTOMER_EMAIL}}"
                        >
                        </stripe-pricing-table>
                    </div>
                    : null}

                <div style={{ padding: 10, paddingTop: 30, fontWeight: 600, fontSize: 16, textTransform: "uppercase" }}>
                    <a href="" style={{ fontWeight: 600 }}>Already a customer?</a>
                </div>

                <div style={{ padding: 0, paddingTop: '20px', fontWeight: 400, fontSize: '14px', textAlign: 'left' }}>
                    By continuing, you confirm that you accept the <a href="#">license agreement</a> and our <a href="#">privacy policy</a>. Your subscription will be renewed unless canceled.
                </div>
            </div>
        </div>
    );
}
