
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Content } from '../Templates'
import { Modal, Space, Button, Skeleton, Affix, notification } from 'antd'
import { Transition } from '../App'
import { format } from 'timeago.js'

export function Privacy({ kernel }) {
    const [loaded, setLoaded] = useState(true)

    return (
        <Content back="/dashboard" backTransition="outRight" topAlign={true}>
            <div id="page">


                <div className="audit" style={{ maxWidth: 700, margin: "0 auto", fontWeight: 500 }}>

                    <h2>Privacy Policy</h2>
                    <p>
                        Effective Date: 2023.11.01
                    </p>

                    <h3>1. Introduction</h3>
                    <p>
                        DOHzel ("we," "us," or "our") is committed to safeguarding the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our web application and related services.
                    </p>

                    <h3>2. Information We Collect</h3>
                    <p>
                        a. <strong>Personal Information:</strong> We may collect personal information, including but not limited to your name, email address, and payment information if applicable.
                    </p>
                    <p>
                        b. <strong>Non-Personal Information:</strong> We may collect non-personal information, such as device information, app usage data, and analytics to improve our services.
                    </p>

                    <h3>3. How We Use Your Information</h3>
                    <p>
                        a. We use personal information to:
                        <ul>
                            <li>Provide and personalize our services</li>
                            <li>Communicate with you</li>
                            <li>Process payments if applicable</li>
                            <li>Address support requests and inquiries</li>
                        </ul>
                    </p>
                    <p>
                        b. We use non-personal information to:
                        <ul>
                            <li>Analyze and improve our services</li>
                            <li>Monitor and report on usage statistics</li>
                        </ul>
                    </p>

                    <h3>4. Disclosure of Information</h3>
                    <p>
                        We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or to protect our rights, privacy, safety, or property.
                    </p>

                    <h3>5. Data Security</h3>
                    <p>
                        We employ reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
                    </p>

                    <h3>6. Data Retention</h3>
                    <p>
                        We retain your personal information only as long as necessary for the purposes outlined in this policy or as required by law.
                    </p>

                    <h3>7. Cookies and Tracking Technologies</h3>
                    <p>
                        We may use cookies and similar tracking technologies to improve your experience on our app. You can modify your browser settings to manage cookies.
                    </p>

                    <h3>8. Third-Party Links</h3>
                    <p>
                        Our app may contain links to third-party websites or services. We are not responsible for the privacy practices or content on these sites. Please review their privacy policies.
                    </p>

                    <h3>9. Changes to this Policy</h3>
                    <p>
                        We may update this Privacy Policy. When we do, we will revise the "Effective Date" at the top of this policy. We encourage you to review this policy periodically.
                    </p>

                    <h3>10. Contact Us</h3>
                    <p>
                        If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at contact@hafnova.com.
                    </p>
                </div>


            </div>
        </Content>
    );
}