import React from 'react'
import logoBig from '../Images/logoBig.png';

function Logo() {
  return (
    <div style={{marginBottom:30,textAlign:'center'}}>
      <img src={logoBig} width={100} />
    </div>
  )
}

export default Logo