import DeviceDetector from "device-detector-js"


export default async function init(kernel) {
    kernel.log("Loading device information")

    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(window.navigator.userAgent);

    kernel.device = {
        desktopApp: window.dohzelDesktop ? true : false,
        iosApp: window.webkit?.messageHandlers?.settingsSetDoH ? true : false,
        ios: {},
        androidApp: window?.Android?.settingsGetDeviceInfo ? true : false,
        android: {},
        google: {},
        desktop: {
            setCentral: ()=>{
                
            }
        },
        browser: device
    }

    // Desktop application
    if(kernel.device.desktopApp === true) {
        kernel.device.desktop = window.dohzelDesktop

        //kernel.device.desktop.setCentral(kernel.config.central)
    }

    // Apple iOS Device
    else if (kernel.device.iosApp === true) {
        // IOS bridge

        kernel.device.ios.settingsGetDeviceInfo = async (url) => {
            const ret = await new Promise((accept) => {
                window.onSettingsGetDeviceInfo = (error, data) => accept({
                    error,
                    data
                })
                window.webkit.messageHandlers.settingsGetDeviceInfo.postMessage("DOHZEL")
            })
            window.onSettingsGetDeviceInfo = () => { }
            return (ret)
        }


        // high priority
        kernel.device.ios.settingsSetDoH = async (url) => {
            const ret = await new Promise((accept) => {
                window.onSettingsSetDoH = (error) => accept({
                    error
                })
                window.webkit.messageHandlers.settingsSetDoH.postMessage(url)
            })
            window.onSettingsSetDoH = () => { }
        }

        // low priority
        kernel.device.ios.settingsGetDoH = async () => {
            const ret = await new Promise((accept) => {
                window.onSettingsGetDoH = (error, data) => accept({
                    error,
                    data
                })
                window.webkit.messageHandlers.settingsGetDoH.postMessage("DOHZEL")
            })
            window.onSettingsGetDoH = () => { }
            return (ret)
        }

        kernel.device.ios.settingsCleanDoH = async () => {
            const ret = await new Promise((accept) => {
                window.onSettingsCleanDoH = (result) => accept(result)
                window.webkit.messageHandlers.settingsCleanDoH.postMessage("DOHZEL");
            })
            window.onSettingsCleanDoH = () => { }
        }

        kernel.device.ios.settingsIsInstalled = async () => {
            const ret = await new Promise((accept) => {
                window.onSettingsIsInstallDoH = (result) => accept(result)
                window.webkit.messageHandlers.settingsIsInstalled.postMessage("DOHZEL")
            })
            window.onSettingsIsInstallDoH = () => { }
            return (ret)
        }

        kernel.device.ios.settingsIsActivated = async () => {
            const ret = await new Promise((accept) => {
                window.onSettingsIsActivated = (result) => accept(result)
                window.webkit.messageHandlers.settingsIsActivated.postMessage("DOHZEL")
            })
            window.onSettingsIsActivated = () => { }
            return (ret)
        }

        // high priority
        kernel.device.ios.settingsOpen = async () => {
            window.webkit.messageHandlers.settingsOpen.postMessage("DOHZEL");
        }

        // implement new logging system
        if(window.webkit.messageHandlers.logMessage) {
            kernel.systemLog = kernel.log.bind(kernel)
            kernel.log = (async (message)=>{
                window.webkit.messageHandlers.logMessage.postMessage(message);
                await kernel.systemLog(message)
            })
        }

    }

    // Apple window?.Android Device
    else if (kernel.device.androidApp === true) {
        // ANDROID bridge

        kernel.device.android.settingsGetDeviceInfo = async () => {
            const ret = await new Promise((accept) => {
                // window.onSettingsGetDeviceInfo = (error, data) => accept({
                //     error,
                //     data,
                // })
                accept(JSON.parse(window?.Android.settingsGetDeviceInfo()))
            })
            window.onSettingsGetDeviceInfo = () => { }
            return (ret)
        }

        // low priority
        kernel.device.android.settingsGetDoH = async () => {
            const ret = await new Promise((accept) => {
                // window.onSettingsGetDoH = (error, data) => accept({
                //     error,
                //     data
                // })
                accept(window?.Android.settingsGetDoH())
            })
            window.onSettingsGetDoH = () => { }
            return (ret)
        }

        kernel.device.android.settingsCleanDoH = async () => {
            const ret = await new Promise((accept) => {
                // window.onSettingsCleanDoH = (result) => accept(result)
                accept(window?.Android.settingsCleanDoH());
            })
            window.onSettingsCleanDoH = () => { }
        }

        kernel.device.android.settingsIsInstalled = async () => {
            const ret = await new Promise((accept) => {
                accept(window?.Android.settingsIsInstalled(kernel.api.dot?.split('/')?.[0]))
            })
            window.onSettingsIsInstallDoH = () => { }
            return (ret)
        }

        kernel.device.android.settingsCopyDoh = async (dot) => {
            const ret = await new Promise((accept) => {
                accept(window?.Android.settingsCopyDoh(dot))
            })
            window.onSettingsCopyDoh = () => { }
            return (ret)
        }

        kernel.device.android.settingsIsActivated = async () => {
            const ret = await new Promise((accept) => {
                // window.onSettingsIsActivated = (result) => accept(result)
                accept(window?.Android.settingsIsActivated(kernel.api.dot?.split('/')?.[0]))
            })
            window.onSettingsIsActivated = () => { }
            return (ret)
        }

        // high priority
        kernel.device.android.settingsOpen = async () => {
            window?.Android.settingsOpen("ACTION_SETTINGS");
        }

        kernel.device.android.settingsLoaded = async () => {
            window?.Android.settingsLoaded();
        }

        // implement new logging system
        if(window?.Android?.logMessage) {
            kernel.systemLog = kernel.log.bind(kernel)
            kernel.log = (async (message)=>{
                window?.Android?.logMessage(message);
                await kernel.systemLog(message)
            })
        }

    }
}

