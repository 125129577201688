
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Content } from '../Templates'
import { Space, Button } from 'antd'
import { Transition } from '../App'

import CountUp from 'react-countup'

import { format } from 'timeago.js'
import { Gauge } from '@ant-design/plots';
import anime from 'animejs'
import Title from './../components/Title';
import BigText from './../components/BigText';

export function Stats({ kernel }) {
    const [loaded] = useState(true)

    const navigate = useNavigate()

    // this is for handling stats
    const [stats, setStats] = useState({
        week: {
            all: 0,
            passed: 0,
            blocking: 0,
            detecting: 0
        }
    })

    useEffect(() => {
        var stop = false
        var timer = null
        async function updateStats() {
            const ret = await kernel.api.getStats('total30d')
            if (!ret || !ret.data?.values)
                return (timer = setTimeout(updateStats, 10000))

            const newStats = { ...stats }
            const lIdx = ret.data.values.length - 1
            const curWeek = ret.data.values[lIdx]
            const prevWeek = ret.data.values[lIdx - 1]

            if (curWeek) {
                newStats.week.all = curWeek[1]
                newStats.week.blocking = curWeek[2]
                newStats.week.blockingPer = newStats.week.blocking * 100 / newStats.week.all
                newStats.week.bypassing = curWeek[3]
                newStats.week.detecting = curWeek[4]
                newStats.week.detectingPer = newStats.week.detecting * 100 / newStats.week.all
                newStats.week.passed = curWeek[5]
            }

            // if (stats.week.blocking !== newStats.week.blocking)
            setStats(newStats)
            if (stop !== true)
                timer = setTimeout(updateStats, 10000)
        }
        timer = setTimeout(updateStats)

        return (() => {
            stop = true
            if (timer) clearTimeout(timer)
        })
    }, [loaded])

    useEffect(() => Transition(['#page'], () => { }, "inRight"), [loaded])

    // **********************

    // manage 
    const config = {
        percent: stats.week.blockingPer / 100,
        type: 'meter',
        //innerRadius: 0.90,
        range: {
            ticks: [0, 1 / 4, 2 / 4, 1],
            color: ['#00B700', '#E5E500', '#FF4C4C'],
        },
        axis: {
            label: {
                formatter(v) {
                    return Number(v) * 100;
                },
            },
            subTickLine: {
                count: 5,
            },
        },
        indicator: {
            pointer: {
                style: {
                    stroke: '#E5EDF0',
                    lineWidth: 1
                },
            },
            pin: {
                style: {
                    stroke: '#E5EDF0',
                    lineWidth: 1,
                    r: 2
                },
            },
        },
        statistic: {
            title: false,
            content: {
                style: {
                    fontSize: '0.8em',
                    fontWeight: 500,
                    color: '#E5EDF0',
                    // lineHeight: '36px',
                },
            },
        },
    }

    const spacing = 20
    return (
        <Content back="/dashboard" backTransition="outRight">
            <div id="page" className='container' style={{ textAlign: "center", maxWidth: '100vw', width: '100vw' }}>
                <div style={{ paddingBottom: 5 }}>
                    <BigText centered bold>
                        Statistics
                    </BigText>
                </div>

                <Gauge {...config} style={{
                                paddingTop: spacing,
                                height: 100,
                                width: 100,
                                margin: "0 auto",
                                fontSize: "0.9em"
                            }} />

                {stats.week.passed > 0 ?
                    <div style={{ paddingTop: spacing * 2, display:'flex', justifyContent:'flex-start', alignItems:'center' }}>
                        <div style={{
                                        width: "153px",
                                        fontWeight: 500,
                                        paddingRight: 10,
                                        textAlign: "right",
                                    }}>
                            Validated<br />
                                        <small>Last 30 days</small>
                        </div>
                        <div style={{
                                        //width: "55%",
                                        fontSize: "2.5em",
                                        paddingLeft: 10,
                                        borderLeft: "3px solid #118242",
                                        fontWeight: 800,
                                        textAlign: "left"
                                    }}>
                            <CountUp end={stats.week.passed} />
                        </div>
                    </div>
                    : null}

                {stats.week.detecting > 0 ?
                    <div style={{ paddingTop: spacing, display:'flex', justifyContent:'flex-start', alignItems:'center'  }}>
                        <table width="100%">
                            <tbody>
                                <tr >
                                    <td style={{
                                        width: "155px",
                                        fontWeight: 500,
                                        paddingRight: 10,
                                        textAlign: "right",
                                    }}>
                                        Detected Threats<br />
                                        <small>Last 30 days</small>
                                    </td>
                                    <td style={{
                                        //width: "55%",
                                        fontSize: "2.5em",
                                        paddingLeft: 10,
                                        borderLeft: "3px solid #0688C4",
                                        fontWeight: 800,
                                        textAlign: "left"
                                    }}>
                                        <CountUp end={stats.week.detecting} />
                                        <div style={{
                                            fontStyle: "italic",
                                            fontSize: "0.25em",
                                            fontWeight: 400,
                                        }}>
                                            or <CountUp end={stats.week.detectingPer} />% of all checks
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    : null}

                {stats.week.blocking > 0 ?
                    <div style={{ paddingTop: spacing }}>
                        <table width="100%">
                            <tbody>
                                <tr >
                                    <td style={{
                                        width: "155px",
                                        fontWeight: 500,
                                        paddingRight: 10,
                                        textAlign: "right",
                                    }}>
                                        Blocked Threats<br />
                                        <small>Last 30 days</small>
                                    </td>
                                    <td style={{
                                        width: "50%",
                                        fontSize: "2em",
                                        paddingLeft: 10,
                                        borderLeft: "3px solid #FF4C4C",
                                        fontWeight: 800,
                                        textAlign: "left"
                                    }}>
                                        <CountUp end={stats.week.blocking} />
                                        <div style={{
                                            fontStyle: "italic",
                                            fontSize: "0.25em",
                                            fontWeight: 400,
                                        }}>
                                            or <CountUp end={stats.week.blockingPer} />% of all checks
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                        
                    </div>
                    : null}

                {/* <div style={{ paddingTop: spacing * 2 }}>
                    <Space >
                        <Button onClick={() => navigate("/dashboard")} style={{
                            color: "#001E2D",
                            backgroundColor: "#CCD3D7",
                            textTransform: "uppercase"
                        }}>
                            ⬅︎ Dashboard
                        </Button>


                    </Space>
                </div> */}


            </div>
        </Content>
    );
}