import { useNavigate } from "react-router-dom";
import { Content } from '../../Templates';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Logo from '../../components/Logo';
import Paragraph from './../../components/Paragraph';
import { useTranslation } from "react-i18next";

export function Welcome({ kernel }) {

    const navigate = useNavigate()
    const { t, i18n, ready } = useTranslation();
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };  
    if (ready) {
        return (
            <Content backTransition="outRight" topAlign={true}>
                <div style={styles.container} class='container'>
                    <Logo />    
                    <div style={styles.content}>
                        <Title centered>{t('Welcome.title')}</Title>
                        <div style={styles.paragraphContainer}>
                            <Paragraph centered>{t('Welcome.content')}</Paragraph>
                        </div>
                    </div>
                    <div><Button onClick={() => navigate('/install/enroll')} >{t('Welcome.button')}</Button></div>
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    container: {
        padding:'0 15px',
        display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', 
    alignItems: 'center', 
        textAlign: 'center',
    },
    centerContent: {
        position: 'relative',
    },
    content: {
        fontSize: 30
    },
    paragraphContainer: {
        marginTop:20,
        marginBottom:50
    }
}