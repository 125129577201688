import React from 'react';

const Button = ({ color, onClick, children, width, style }) => {
  const buttonStyle = {
    // Add other styles such as background-color, color, border, etc.
    background: color ?? 'transparent',
    borderRadius: 8,
    paddingLeft:10,
    paddingRight: 10,
    //flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    minWidth:width ?? 100,
    //maxWidth:200
  };

  const textStyle = {
    color: '#FFF',
    fontSize: 15,
    paddingTop: 0,
    margin:0
    
  };

  return (
    <button style={{...buttonStyle, ...style}} onClick={onClick}>
      <p style={textStyle}>{children}</p>
    </button>
  );
};

export default Button;