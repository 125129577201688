import { useNavigate, useLocation } from "react-router-dom";
import { Content } from '../../Templates'
import Title from '../../components/Title';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import Logo from '../../components/Logo';
import { useTranslation } from "react-i18next";

export function MDM({ kernel }) {

    const navigate = useNavigate()
    const { state } = useLocation();

    const { t, i18n, ready } = useTranslation();

    if (ready) {
        return (
            <Content back="PREVIOUS" backTransition="outRight" topAlign={true}>
                <div id="page">
                    <div style={styles.container} className='container'>
                        <Logo /> 
                        <Title centered>{t('Mdm.title')}</Title>
                        <div style={styles.content}>
                            <Paragraph centered>
                                {t('Mdm.content', {profileName: state?.profileName})}
                            </Paragraph>
                        </div>
                        <div style={styles.buttonContent}>
                                <Button onClick={() => navigate('/install/EnrollCode')} modifier="cta">
                                    {t('Mdm.backButton')}
                                </Button>
                                <Button onClick={() => navigate('/install/Device')} modifier="cta">
                                    {t('Mdm.continueButton')}
                                </Button>
                            </div>
                    </div>
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    content: {
        marginTop: 20,
        marginBottom:50
    },
    buttonContent: {
        marginTop:'20vh',
        margin: 30,
        display: 'flex',
        justifyContent: 'space-between',
        gap:18
    },
}