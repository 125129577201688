import React from 'react';

const LogInfo = ({ label, value }) => {

  const labelStyle = {
    //color: '#FFF',
    fontSize: 16,
    margin: 0,
    fontWeight: 400,
    paddingBottom:3
  };

  const valueStyle = {
    fontSize: 16,
    margin: 0,
    fontWeight: 700,
    paddingLeft:10
  };

  return (
      <div style={{marginBottom:10, display:'flex', wordBreak: 'break-all'}}>
        <p style={labelStyle}>{label}</p><p style={valueStyle}>{value}</p>
      </div>
  );
};

export default LogInfo;